import React, { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./Header";
import * as XLSX from 'xlsx';

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

function Data() {
  const [positions, setPositions] = useState([]);
  const [viewMode, setViewMode] = useState("open");
  const [selectedToken, setSelectedToken] = useState("all");
  const [tokens, setTokens] = useState([]);
  // New state for copytrade_wallet
  const [selectedWallet, setSelectedWallet] = useState("all");
  const [wallets, setWallets] = useState([]);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [selectedWalletName, setSelectedWalletName] = useState("all");
  const [walletNames, setWalletNames] = useState([]);

  useEffect(() => {
    fetchData();
  }, [viewMode, selectedToken, selectedWallet, startDateTime, endDateTime, selectedWalletName]);

  const fetchData = async () => {
    try {
      const { data: openData, error: openError } = await supabase
        .from("open")
        .select("*");

      const { data: closeData, error: closeError } = await supabase
        .from("close")
        .select("*");

      const { data: partialCloseData, error: partialCloseError } =
        await supabase.from("partial_close").select("*");

      if (openError || closeError || partialCloseError) {
        throw new Error("Error fetching data");
      }

      const allTokens = [
        ...new Set([...openData].map((item) => item.token_out)),
      ];
      setTokens(["all", ...allTokens]);

      const allWallets = [
        ...new Set([...openData].map((item) => item.copytrade_wallet)),
      ];
      setWallets(["all", ...allWallets]);

      const allWalletNames = [
        ...new Set([...openData].map((item) => item.wallet_name)),
      ];
      setWalletNames(["all", ...allWalletNames]);

      let filteredPositions = processData(
        openData,
        closeData,
        partialCloseData
      );

      // Filter by view mode
      if (viewMode === "open") {
        filteredPositions = filteredPositions.filter(pos => pos.status === "open");
      } else if (viewMode === "closed") {
        filteredPositions = filteredPositions.filter(pos => pos.status === "closed");
      }

      // Filter by date and time range if both start and end are selected
      if (startDateTime && endDateTime) {
        filteredPositions = filteredPositions.filter((pos) => {
          const posDate = new Date(pos.timestamp);
          return posDate >= startDateTime && posDate <= endDateTime;
        });
      }

      if (selectedToken !== "all") {
        filteredPositions = filteredPositions.filter(
          (pos) => pos.token_out === selectedToken
        );
      }

      if (selectedWallet !== "all") {
        filteredPositions = filteredPositions.filter(
          (pos) => pos.copytrade_wallet === selectedWallet
        );
      }

      if (selectedWalletName !== "all") {
        filteredPositions = filteredPositions.filter(
          (pos) => pos.wallet_name === selectedWalletName
        );
      }

      // Sort positions by timestamp, latest first
      filteredPositions.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      setPositions(filteredPositions);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const processData = (openData, closeData, partialCloseData) => {
    const closedPositionIds = new Set(
      closeData.map((item) => item.position_id)
    );

    const processedData = openData.map((openItem) => {
      let status = "open";
      let profit = "0 (0%)";
      let left = `${openItem.token_out_amount} (100%)`;

      if (closedPositionIds.has(openItem.position_id)) {
        status = "closed";
        const closeItem = closeData.find(
          (item) => item.position_id === openItem.position_id
        );
        profit = `${closeItem.profit_amount} (${closeItem.profit_percentage}%)`;
        left = "0 (0%)";
      } else {
        const partialCloses = partialCloseData
          .filter((pc) => pc.position_id === openItem.position_id)
          .sort((a, b) => b.id - a.id);

        if (partialCloses.length > 0) {
          const latestPartialClose = partialCloses[0];
          profit = `${latestPartialClose.profit_amount} (${latestPartialClose.profit_percentage}%)`;
          left = `${latestPartialClose.amount_left} (${latestPartialClose.percentage_left}%)`;
        }
      }

      // Format the 'buy' column
      const buy = `${openItem.token_in_amount} ${openItem.token_in} - ${openItem.token_out_amount} ${openItem.token_out}`;

      // Format the price_per_token
      const formattedPrice = parseFloat(openItem.price_per_token).toFixed(8);

      const formattedTimestamp = parseInt(openItem.timestamp) * 1000;

      return {
        ...openItem,
        status,
        profit,
        left,
        buy,
        price_per_token: formattedPrice,
        timestamp: formattedTimestamp,
      };
    });

    return processedData.map((item) => {
      console.log("item", item);
      return {
        timestamp: item.timestamp,
        status: item.status,
        buy: item.buy,
        price_per_token: item.price_per_token,
        left: item.left,
        profit: item.profit,
        mode: item.mode,
        wallet_name: item.copytrade_wallet,
        slot: item.slot,
        distance: item.distance,
        ...item, // Include remaining fields
      };
    });
  };

  // Add this helper function to shorten the wallet address
  const shortenWalletAddress = (address) => {
    if (address === "all") return "All";
    if (!address) return "";
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const exportToExcel = () => {
    const dataToExport = positions.map(pos => {
      const excelDate = (new Date(pos.timestamp).getTime() / 86400000) + 25569;
      return {
        'Date': excelDate,
        'Time': excelDate,
        ...pos // Spread the rest of the position data
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Set column widths
    worksheet['!cols'] = [
      { wch: 12 }, // Date
      { wch: 12 }, // Time
      // Add width for other columns as needed
    ];

    // Format Date and Time columns
    for (let i = 2; i <= dataToExport.length + 1; i++) {
      worksheet[`A${i}`].z = 'dd/mm/yyyy';
      worksheet[`B${i}`].z = 'hh:mm:ss';
    }

    // Find the profit column
    const profitColIndex = Object.keys(dataToExport[0]).findIndex(key => key.toLowerCase().includes('profit'));
    const profitColLetter = XLSX.utils.encode_col(profitColIndex);

    // Apply conditional formatting to the profit column
    for (let i = 2; i <= dataToExport.length + 1; i++) {
      const cellRef = `${profitColLetter}${i}`;
      if (!worksheet[cellRef]) continue;

      const cellValue = worksheet[cellRef].v;
      if (typeof cellValue !== 'string') continue;

      // Extract percentage from the string
      const percentageMatch = cellValue.match(/\(([-\d.]+)%\)/);
      if (!percentageMatch) continue;

      const percentageValue = parseFloat(percentageMatch[1]);
      if (isNaN(percentageValue)) continue;

      if (percentageValue < 0) {
        worksheet[cellRef].s = { fill: { fgColor: { rgb: "FFFF0000" } } }; // Red
      } else {
        worksheet[cellRef].s = { fill: { fgColor: { rgb: "FF00FF00" } } }; // Green
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Positions");
    
    XLSX.writeFile(workbook, "positions_data.xlsx");
  };

  return (
    <>
      <Header />
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">View Options</h3>
            <div className="flex items-center">
              <span className="mr-2">Status:</span>
              <select
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
                className="p-2 border rounded flex-grow"
              >
                <option value="open">Open</option>
                <option value="closed">Closed</option>
                <option value="all">All</option>
              </select>
            </div>
            <button className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out my-2" onClick={exportToExcel}>Export to Excel</button>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Filter Options</h3>
            <div className="flex items-center mb-2">
              <span className="mr-2">Token:</span>
              <select
                value={selectedToken}
                onChange={(e) => setSelectedToken(e.target.value)}
                className="p-2 border rounded flex-grow"
              >
                {tokens.map((token) => (
                  <option key={token} value={token}>
                    {token}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <span className="mr-2">Wallet:</span>
              <select
                value={selectedWallet}
                onChange={(e) => setSelectedWallet(e.target.value)}
                className="p-2 border rounded flex-grow"
              >
                {wallets.map((wallet) => (
                  <option key={wallet} value={wallet}>
                    {shortenWalletAddress(wallet)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Date & Time Range</h3>
            <div className="flex flex-col">
              <DatePicker
                selected={startDateTime}
                onChange={(date) => setStartDateTime(date)}
                selectsStart
                startDate={startDateTime}
                endDate={endDateTime}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="dd-MM-yyyy HH:mm"
                placeholderText="Start Date & Time"
                className="p-2 border rounded mb-2 w-full"
              />
              <DatePicker
                selected={endDateTime}
                onChange={(date) => setEndDateTime(date)}
                selectsEnd
                startDate={startDateTime}
                endDate={endDateTime}
                minDate={startDateTime}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="dd-MM-yyyy HH:mm"
                placeholderText="End Date & Time"
                className="p-2 border rounded w-full"
              />
            </div>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Wallet Name Filter</h3>
            <div className="flex items-center">
              <select
                value={selectedWalletName}
                onChange={(e) => setSelectedWalletName(e.target.value)}
                className="p-2 border rounded flex-grow"
              >
                {walletNames.map((walletName) => (
                  <option key={walletName} value={walletName}>
                    {walletName === "all" ? "All Wallets" : walletName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Timestamp
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Buy
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Birdeye
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Price Per Token
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Left
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Profit
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Mode
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Wallet Name
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Slot
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Distance
                </th>
                {Object.keys(positions[0] || {})
                  .filter(
                    (key) =>
                      ![
                        "timestamp",
                        "status",
                        "buy",
                        "price_per_token",
                        "left",
                        "profit",
                        "mode",
                        "slot",
                        "distance",
                      ].includes(key)
                  )
                  .map((key) => (
                    <th
                      key={key}
                      className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {key}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {positions.map((position, index) => {
                console.log(position);
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {new Date(position.timestamp)
                        .toLocaleString("en-GB", {
                          timeZone: "UTC",
                          hour12: false,
                        })
                        .replace(",", "")}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {position.status}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      <a
                        href={`https://bullx.io/terminal?chainId=1399811149&address=${position.token_address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 underline"
                      >
                        {position.buy}
                      </a>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      <a
                        href={`https://birdeye.so/find-trades/${position.token_address}?chain=solana`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-700 underline"
                      >
                        Birdeye
                      </a>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {position.price_per_token}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {position.left}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {position.profit}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {position.mode}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {position.wallet_name}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {position.slot}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {position.distance}
                    </td>
                    {Object.entries(position)
                      .filter(
                        ([key]) =>
                          ![
                            "timestamp",
                            "status",
                            "buy",
                            "price_per_token",
                            "left",
                            "profit",
                            "mode",
                            "slot",
                            "distance",
                          ].includes(key)
                      )
                      .map(([key, value]) => (
                        <td
                          key={key}
                          className="px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                        >
                          {value?.toString() || ""}
                        </td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}


export default Data;